<template>
  <div>
    <div v-if="isVisible" class="overlay" @click="close">
      <div class="modal" :class="{ fadeOut: isFadingOut }">
        <div class="chanpin">
          <img class="fudai_tuijain_chanpin_logo" src="@/assets/img/fudai_tuijain_chanpin_logo.png" @click="close" />
          <div class="name">
            <p>BRD</p>
            <p>Pinjaman Online Cepat</p>
          </div>
        </div>
        <img class="fudai_tuijain_chanpin_btn" src="@/assets/img/fudai_tuijain_chanpin_btn.png" @click="close" />
      </div>
    </div>
    <div class="advertising" v-show="advertisingShow" @click="goAdvertising">
      <img src="@/assets/img/advertising_close.png" @click="advertisingShow = false" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redrpaymentTime: {
      type: String,
      default: ''
    },
    orderStatus: {

    }
  },
  data () {
    return {
      isVisible: false,
      isFadingOut: false,
      advertisingShow: true
    }
  },
  created () {
    this.ShowModal()
  },
  methods: {
    ShowModal () {
      if (this.$props.orderStatus === 6) {
        const tuijianOrderStatus6 = localStorage.getItem('fudai_tuijain_chanpin_orderStatus6')
        if (!tuijianOrderStatus6) {
          this.open()
        }
        localStorage.setItem('fudai_tuijain_chanpin_orderStatus6', true)
        return
      }
      if (this.$props.orderStatus === 5) {
        const tuijianOrderStatus5 = localStorage.getItem('fudai_tuijain_chanpin_orderStatus5')
        if (!tuijianOrderStatus5) {
          // 如果当前时间在到期时间或到期时间前一天，则返回 false
          const result = this.isCurrentTimeBeforeExpiration()
          if (result) {
            this.open()
          }
          localStorage.setItem('fudai_tuijain_chanpin_orderStatus5', true)
        }
      }
    },
    open () {
      this.isVisible = true
      this.isFadingOut = false
    },
    close () {
      this.isFadingOut = true
      setTimeout(() => {
        this.isVisible = false
      }, 1000) // 动画持续时间与 CSS 动画保持一致
    },
    // BRD
    goAdvertising () {
      window.location = `http://ojk.pasarpinjaman.cc/brd?tm=${Date.now()}`
    },
    // 判断是不是到期和到期前一天 如果是则不弹 否则弹框
    isCurrentTimeBeforeExpiration () {
      const expirationDate = this.convertDateFormat(this.$props.redrpaymentTime)
      // 获取当前时间戳
      const currentTimestamp = Date.now()
      // 转换到期时间为时间戳
      const expirationTimestamp = new Date(expirationDate).getTime()
      // 判断expirationTimestamp是不是NAN 如果是返回false
      if (isNaN(expirationTimestamp)) {
        return false
      }
      // 计算到期时间前一天的时间戳
      const oneDayInMillis = 24 * 60 * 60 * 1000 // 1天的毫秒数
      const expirationDayBeforeTimestamp = expirationTimestamp - oneDayInMillis
      // 判断当前时间是否在到期时间或到期时间前一天的范围内，或者当前时间是否已经超过到期时间
      if (currentTimestamp >= expirationDayBeforeTimestamp && currentTimestamp <= expirationTimestamp) {
        return false
      }
      // 判断当前时间是否已经超过到期时间
      if (currentTimestamp > expirationTimestamp) {
        return false
      }
      return true
    },
    // 将到期日'13/09/2024' 转换成2024-09-13
    convertDateFormat (dateStr) {
      const [day, month, year] = dateStr.split('/').map(Number)
      const date = new Date(Date.UTC(year, month - 1, day))
      const yearStr = date.getUTCFullYear()
      const monthStr = String(date.getUTCMonth() + 1).padStart(2, '0')
      const dayStr = String(date.getUTCDate()).padStart(2, '0')
      return `${yearStr}-${monthStr}-${dayStr}`
    }

  }
}
</script>

<style scoped lang="less">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  width: 572px;
  height: 599px;
  transition: all 1s ease;
  background: url("~@/assets/img/fudai_tuijain_chanpin_bg.png");
  background-size: 100% 100%;

  .chanpin {
    position: absolute;
    bottom: 180px;
    left: 30px;
    display: flex;
    align-items: center;

    .fudai_tuijain_chanpin_logo {
      width: 142px;
      height: 142px;
      border-radius: 50%;
    }
  }

  .name {
    font-weight: 500;
    font-size: 30px;
    color: #000000;

    p:nth-child(2) {
      font-size: 24px;
      color: #7e7e7e;
    }
  }

  .fudai_tuijain_chanpin_btn {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 296px;
  }
}

.fadeOut {
  // transform: translateY(100vh) scale(0); /* 从中间向下移动并缩小 */
  transform: translate(-49vw, 50vh) scale(0.1);
  /* 向左下角移动并缩小 */
  opacity: 0;
  /* 透明度降低 */
  width: 100px;
  height: 100px;
}

.advertising {
  height: 90px;
  width: 100%;
  position: fixed;
  bottom: 15px;
  left: 0;
  background: url("~@/assets/img/fudai_tuijain_chanpin_download.png") no-repeat;
  background-size: 100% 100%;

  img {
    position: absolute;
    width: 28px;
  }
}
</style>
