<template>
  <div class="content">
    <!-- 券 -->
    <van-dialog class="ticketDialog" v-model="ticketVisible" :showConfirmButton="false">
      <div class="ticket">
        <h2>{{ money }}</h2>
        <img class="ticket_btn" src="@/assets/img/ticket_btn.png" alt="" @click="ticketClose" />
        <img class="ticket_close" src="@/assets/img/ticket_close.png" alt="" @click="ticketClose" />
      </div>
    </van-dialog>
    <van-dialog class="ticketDialog2" v-model="ticketVisible2" :showConfirmButton="false">
      <div class="ticket2">
        <div class="title">
          Selamat
        </div>
        <div class="content">
          Dana pinjaman telah masuk ke rekening Anda. Pujian bintang lima dapat mengurangi bunga dan menambah jumlah
          pinjaman minimal Rp300.000!
        </div>
        <div class="btn"></div>
        <div class="btn_text">
          <div @click="ticketVisible2 = false">Menyerah</div>
          <div @click="ticketVisible2 = false">
            <a href="https://apps.apple.com/id/app/okpl-pinjam-tunai-online/id6740871375?l=id">
              Ulasan bagus
            </a>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>

import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.use(Toast)
export default {
  props: {
    money: {
      default: 'Rp0'
    }
  },
  data () {
    return {
      ticketVisible: false,
      ticketVisible2: false

    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  created () {
    // 券
    if (!localStorage.getItem('showTicket')) {
      this.ticketVisible = true
    }
  },
  methods: {
    // 券弹窗关闭
    ticketClose () {
      Toast('Berhasil ambil, gunakan pada pinjaman berikutnya')
      localStorage.setItem('showTicket', true)
      setTimeout(() => {
        try {
          this.ticketVisible = false
          if (window.webkit.messageHandlers.uhsdfsa) {
            window.webkit.messageHandlers.uhsdfsa.postMessage('')
            return
          }
          this.ticketVisible2 = true
        } catch (error) {
          this.ticketVisible2 = true
        }
      }, 700)
    }
  }
}
</script>

<style scoped lang='less'>
.commentDialog {
  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #fff;
    text-align: center;
  }

  .toStore-btn {
    .submit-btn;
    width: 500px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
  }

  .hiddenIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.ticketDialog {
  background: none;
  width: 700px;
  padding-bottom: 130px;

  .ticket {
    background: none;
    position: relative;
    height: 730px;
    background: url("~@/assets/img/ticket_bg.png") no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 9;

    h2 {
      text-align: center;
      padding-top: 330px;
      color: #fb0003;
      font-size: 35px;
    }

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .ticket_btn {
      bottom: 40px;
      width: 300px;
    }

    .ticket_close {
      bottom: -130px;
      width: 100px;
    }
  }
}

.ticketDialog2 {
  background: none;

  .ticket2 {
    height: 420px;
    background: linear-gradient(to bottom, #553abb, #7f69cb);
    padding: 30px;
    border-radius: 40px;

    .title {
      font-size: 46px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }

    .content {
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 40px;
    }

    .btn_text {
      display: flex;
      justify-content: space-around;
      font-size: 28px;
      color: #fff;
      margin-top: 60px;

      div:first-child {
        background: #ddd;
        color: #553abb;
      }

      div {
        width: 230px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background: #553abb;
        border-radius: 100px;
      }

      // 禁用a标签默认样式
      a {
        color: #fff;
      }

    }
  }
}

/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
</style>
